import { Index as ValueProps } from "@/components/ValueProps";
import { Navbar } from "@/components/NewNavigation/Navbar";
import { Index as HeroBanner } from "@/components/HeroBanner";
import { Index as MarketingBanner } from "@/components/MarketingBanner";
import { ProductCarousel } from "@/components/ProductCarousel";
import { Index as Masonry } from "@/components/Masonry";
import { Index as MarketingFeaturesTile } from "@/components/MarketingFeaturesTile";
import { Index as MarketingHeroBanner } from "@/components/MarketingHeroBanner";
import { Index as PromoSection } from "@/components/PromoSection";
import { Index as PricingGrid } from "@/components/PopupLauncher/PricingGrid";
import { Index as MetaTags } from "@/components/MetaTags/Index";
import { Index as IndustryTiles } from "@/components/IndustryTiles";
import { Index as DocumentProductTiles } from "@/components/DocumentProductTiles";
import { Index as LongProductTile } from "@/components/LongProductTile";
import { MarketingBlockAnimated } from "@/components/MarketingBlockAnimated";
import { Index as Disclaimer } from "@/components/Disclaimer";
import { Index as PickUpTiles } from "@/components/PickUpTiles";
import { Index as PopupLauncher } from "@/components/PopupLauncher";
import { Index as ProductTypeTile } from "@/components/ProductTypeTile";
import { Index as Breadcrumbs } from "@/components/Breadcrumbs";
import { Index as BreadcrumbsExternal } from "@/components/BreadcrumbsExternal";
import { Index as PrintSolutionsLayout } from "@/components/PrintSolutionsLayout";
import { Index as MarketingLandingBanner } from "@/components/MarketingLandingBanner";
import { Index as SameDayBanner } from "@/components/SameDayBanner";
import { WaysToDesignCTA } from "@/components/WaysToDesignCTA";
import { HeroGallery } from "@/components/HeroGallery";
import { Index as ScrollableTiles } from "@/components/ScrollableTiles";
import { PrintingSpecials } from "@/components/PrintingSpecials";
import { Index as VideoSpotlight } from "@/components/VideoSpotlight";
import { Index as MarketingHolidayBanner } from "@/components/MarketingHolidayBanner";
import { AuthIframe } from "@/components/AuthIframe";
import { ScrollableValueTiles } from "@/components/ScrollableValueTiles";
import { Accordion } from "@/components/Accordion";
import { DesignCategories } from "@/components/DesignCategories";
import { ProductInspiration } from "@/components/ProductInspiration";
import { PromoRibbon } from "@/components/PromoRibbon";
import { Padding } from "@/components/Padding";
import { RelatedProducts } from "./RelatedProducts";
import { PageDivider } from "./utils/PageDivider";
import { ImageGallery } from "./ImageGallery";
import { BentoBox } from "./BentoBox";
import { HeadingBlog } from "./HeadingBlog";
import { TextBlog } from "./TextBlog";

export const mappingDefault = {
  Navbar,
  MarketingBanner,
  HeroBanner,
  MarketingHeroBanner,
  ValueProps,
  Masonry,
  ProductCarousel,
  MarketingFeaturesTile,
  PromoSection,
  MetaTags,
  PricingGrid,
  IndustryTiles,
  DocumentProductTiles,
  LongProductTile,
  MarketingBlockAnimated,
  MarketingHolidayBanner,
  PickUpTiles,
  Disclaimer,
  PopupLauncher,
  Breadcrumbs,
  BreadcrumbsExternal,
  ProductTypeTile,
  PrintSolutionsLayout,
  MarketingLandingBanner,
  WaysToDesignCTA,
  HeroGallery,
  SameDayBanner,
  ScrollableTiles,
  PrintingSpecials,
  VideoSpotlight,
  AuthIframe,
  ScrollableValueTiles,
  Accordion,
  DesignCategories,
  ProductInspiration,
  PromoRibbon,
  Padding,
  RelatedProducts,
  PageDivider,
  ImageGallery,
  BentoBox,
  HeadingBlog,
  TextBlog,
};
